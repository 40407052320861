/*========== Blog css =========*/

.sidebar-title {
	margin-bottom: 2rem !important;
}
.m-type-3{
	.card-img{
		height: auto;
	}	
}
.categories-list {
	ul {
		li {
			a {
				color: $gray-700;
				display: block;
				transform: translateX(0px);
				transition: all 0.4s ease-in-out 0s;
				&:hover {
					color: $blue;
					transform: translateX(10px);
				}
			}
		}
	}
}
.card-footer i{
	color: $blue;
}
.categories-tag {
	a {
		font-size: 14px;
		margin: 0 3px;
		color: $gray-600;
		&:hover {
			color: $blue;
		}
	}
}

.popular-post {
	.media-body {
		p {
			a {
				color: $gray-700;
				&:hover {
					color: $blue;
				}
			}
		}
	}
}

.card-img {
	@extend .border-rad;
}
.blog-content-p p{
margin-bottom:1.5rem;
}
// user-comment
.user-comment{
	hr{
		margin-bottom:1.5rem;
		margin-top:1.5rem;
	}
}
//pager
.pager.d-flex.justify-content-between {
	background: #637282;
	padding: 1.5rem 2rem;
	border-radius: 5px;
	.btn{
		background:#fff;
		&:hover{
			background:inherit;
		}
	}
}
.blog-masonry-wrapper {
	.card-title {
		font-size: 26px;
		line-height: 1.3;
	}
	.card:hover .black-tp-layer {
		@include background-opacity($blue, 0.7);
	}

}

.blog-alternate:nth-child(2n) > div:first-child > div:first-child {
	order: 2;
}
.blog-alternate{
  &:last-child{
  	margin-bottom:0 !important;
  }
}
.blog-detail .media {
	margin-top: 20px;
}

.card-footer,
.blog-meta {
	a {
		&:hover {
			color: $blue !important;
		}
	}
}
//Team
.team-list{
	.overlay-wrap{
	margin-bottom: 1.875rem;
		
}
	.role{
			margin-bottom: 1.25rem;
		}
}

//socail

.social-icons{
	ul >li{
		&:first-child{
			margin-left: 0 !important;
			i{
				margin-left:0 !important;
			}
		}
	}
}
//Widget

.angly-widget{
	margin-bottom: 2rem !important;
	padding-bottom: 2rem !important;
		&:first-child{
			margin-top:0 !important;
			}
	.border-bottom{
		&:last-child{
			border:0;
		}	
	}
	>div{
		&:last-child{
			margin-bottom: 0!important;
		}
	}
}

div.card-columns{
	column-gap: 2.25rem;
}
.contact-info h2 {
	font-size: 1.625rem;
}
.blog-detail-thumb{
	



height: 100vh;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;
}
/*======== Blog Responsive =======*/

@media (max-width: 575px) {


	.w-65 {
	width: 90%;
}
	.col-xs-4 {
		-moz-box-flex: 0;
		flex: 25%;
		max-width: 25%;
	}
	.coupon-code{
margin-bottom:1rem;
	}
	.product-wrapper{
		.list-p-detail li{
width:85px;
	}
	}
	
}
@media(max-width:1100px){
	.blog-masonry-wrapper{
		.card-img-overlay{
			p,span,img{
				display: none !important;
			}
		}
	}
}
@media (max-width: 991px) {

	//Woo
.cart-number input[type="number"]{
	width:60px;
}
	.blog-alternate:nth-child(2n) > div:first-child > div:first-child {
		order: 0;
	}
	.about-content.py-5.px-4 {
		padding: 2rem 0 0rem 0 !important;
	}
	.blog-alternate {
		&:last-child {
			> div {
				margin-bottom: 0 !important;
			}
		}
	}

	.contact-info {
		.google-maps {
			margin-bottom: 3.5rem !important;
		}
	}
	.card-img-overlay.black-tp-layer {
		.card-text {
			display: none;
		}
	}
	.m-md-b{
margin-bottom:2rem !important;
	}
	.chk-video-section .section-title-wrapper {
	margin-bottom: 2rem !important;
}
}
@media(min-width:768px){
	.blog-share{
	 text-align: right;
	}
}
@media(max-width:767px) {
	.user-comment .media-heading {
	margin-bottom: 0.4rem !important;
}
	.user-comment .d-flex {
	display: block !important;
}
	.navbar-brand {
		width: 120px;
	}
.chk-video-section .section-title-wrapper {
	margin-bottom: 2rem !important;
}
.team-list{
	margin-bottom:2rem;
}
	.pricing-card {
		text-align: center;
	}
	.contact-home-info {
		padding-top: 0 !important;
	}

	.skills-wrapper {
		.row > div {
			margin-bottom: 1rem;
		}
	}
	.border-style::after,
	.border-style::before {
		content: none;
		display: inline !important;
	}
	.under-maintenance {
		h2 {
			font-size: 2rem !important;
		}
	}
	button.close {
		display: none !important;
	}
	.m-sm-b {
		margin-bottom: 2rem;
	}
	.search-form .module-container {
		margin: 0 1rem;
	}
	.user-comment-section {
		.btn {
			margin-top: 1rem;
		}
	}
	.banner-content {
		h3 {
			font-size: 1.3em !important;
		}
	}
}

@media(max-width:575px) {
	.search .send-mail {
		margin: 0 1rem;
	}
	.m-xs-b {
		margin-bottom: 1rem;
	}
	.m-md-b {
		margin-bottom: 2rem;
	}
	.list-p-detail li {
		display: inline-block;
		margin-right: 1rem;
		&:last-child {
			margin: 0;
		}
	}
	.footer-wrapper-2 .bottom-footer ul li {
		margin-bottom: 5px;
	}
	.session-wrapper {
		padding: 7rem 0 2rem !important;
	}
	.user-comment .media img {
		width: 30px;
		height: auto;
	}
	div.banner-content h3 {
		font-size: 1.2em !important;
	}
	.banner-content .btn {

	display: block;

	width: 70vw;
	margin: 20px auto !important;
}
}