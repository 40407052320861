/*========== Home Css ==========*/
.site-banner {
  background: url(../../../assets/img/logos/fondo-02.png); //fondo inicio
  background-repeat: no-repeat;
  // background-position: 100% 100%; 
  background-position: center;
  // background-size: cover;
  height: 100vh;
}
.banner-wrapper {
  margin-top: 3rem;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.banner-inner {
  flex: 0 0 auto;
  width: 100%;
  h4{
    margin-bottom:1.3em;
  }
}
.inverse{
  background:$white;
  color:$gray-800 !important;
}
.content {
  margin: 0 auto;
  overflow-x: visible;
  padding: 10px 10px 1px;
  width: 100%;
}
.banner-content h2 {
  position: relative;
  width: 70%;
}
.section-title-wrapper {
  margin-bottom:3.75rem;
  h2 {
   // font-size: 2rem;
    margin-bottom: 1.875rem;
  }
}
.section-heading {

    .lead{
      font-weight:normal;
      color:$gray-800;
    }
}
@media(max-width:768px){
    .banner-content h2{
        font-size: 3rem;
    }
}
@media(max-width:480px){
    .banner-content h2{
        font-size: 2rem !important;
    }
}

.Gallery-grid .overlay-wrap img{
    border-radius: 10px;
}
/*========== Service grid Css ==========*/
.info-card {
  .icon-wrap {
    i {
      transform: translateY(0px);
      transition: transform 0.4s ease 0s, box-shadow 0.4s ease 0s;
    }
  }
  .info-title {
    transition: color 0.4s ease 0s;
    // font-size: 24px;
  }
  &:hover {
    .icon-wrap i {
      transform: translate3d(0px, -0.8rem, 0px);
    }
    .text-warning + .info-title {
      color: $orange;
    }
    .text-primary + .info-title {
      color: $blue;
    }
    .text-info + .info-title {
      color: $teal;
    }
  }
}
/*========== Video Section =========*/
.chk-video-section, .feature-slide-content {
    ul {
        li {
            transform: translateX(0px);
            transition: all 0.3s linear 0s;
            &:hover {
                color: rgb(0, 102, 235);
                transform: translateX(10px);
            }
        }
    }
    .card {
        box-shadow: 0 6px 24px 0 rgba(0,0,0,0.5);
    }
}
/*==========  Mobile feature ========*/
.mob-feature, .contact-section {
  i {
    transform: scale(1);
    transition: all 0.6s linear 0s;
  }
  .media:hover i {
    transform: scale(1.3);
  }
}
.mob-feature {
  background: url(../../../assets/img/mobile-feature.jpg);
  background-position: 100% 100%;
  background-size: cover;
  background-attachment: fixed;
}
@media(max-width:767px){
	.media.mb-5:last-child {
	margin-bottom: 0 !important;
}
}

/*========== Post Section ===========*/
.post-section, .blog-masonry-wrapper {
  .card {
    transition: all 0.3s ease-in 0s;
    box-shadow: 0 0 10px $gray-500;
    .card-title, .card-footer {
        background-color: transparent;
        a {
            color: $gray-700;
            &:hover {
                color: $blue;
            }
        }
    }
    &:hover {
      box-shadow: 0 6px 28px rgba(0, 0, 0, 0.2);
      transition: all 0.5s ease 0s;
      transform: translateY(-20px);
    }
  }
}
.work-with-us {
  background: url(../../../assets/img/work-with-us.png);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: cover;
  padding: 6rem 0;
}

/*====== Team Section =======*/
.team {
  .card {
    transition: all 0.4s ease-in 0s;
    img {
      transition: all 0.4s ease-in 0s;
    }
    &:hover {
      transform: translateY(-20px);
      img {
        @extend .shadow-lg;
      }
      .card-img-overlay {
        opacity: 1;
        transition: all 0.4s ease-in-out 0s;
      }
      .ih-fade-down {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }
  .overlay-wrap:hover {
    .card-img-overlay {
      box-shadow: none;
    }
  }
}
/*======== Pricing Section ==============*/
.pricing-grid {
  background-color: $blue;
  padding: 6rem 0;
  @extend .team;
}
.pricing-card {
    background-color: $card-bg;
    box-shadow: 0 3px 14px 0 rgba(0,0,0,0.3);
    transition: all 0.4s ease-in 0s;
    padding: 3.2rem 2rem !important;
    &:hover {
        transform: translateY(-20px);
        box-shadow: 0 18px 30px 0 rgba(0,0,0,0.3);
    }
}
/*======== Testimonial Section ==========*/
.testimonial-section {
    background: url(../../../assets/img/testimonial.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size:cover;
    .black-tp-layer {
      padding: 7rem 0;
    }
    .carousel-control-prev, .carousel-control-next {
      opacity: 0.9;
    }
}
/*======== Client Section ==========*/

.client-logo{
  padding:3rem 0 2rem 0;
}

/*======= Subscribe Section =============*/
.py-6 {
  padding: 6rem 0;
}
.subscribe-section {
  input[type="email"] {
    background-color: lighten(#a6a5a8, 6.5%);
    border: 0;
    padding: 10px 10px 10px 40px;
    position: relative;
  }
  .has-icon {
    position: absolute;
    left: 19px;
    top: 11px;
    font-size: 27px;
  }
  .form-control::placeholder {
    font-size: 14px;
    color: $white;
  }
}

.send-msg {
  background-color:  rgb(0 0 0 / 84%);
  background: url(../../../assets/img/logos/trabajadores-control-habitacion.jpg);

  background-position: center center;
  background-attachment:fixed;
  background-size:cover;
  .warning-tp-layer {
    padding: 7rem 0;
  }
  
}
/*======== Responsive ========*/

@media (max-width:450px) {
  .banner-content {
    h2 {
      font-size: 2rem;
    }
    .btn {
      margin-right: 0 !important;
    }
  }
}
@media (max-width:575px) {
  .navbar {
    padding: 15px;
  }
  .support-card .flex-column.mr-5 {
  margin: 0 !important;
}
}
/*======== Spacing  ========*/
.section-gap{
padding-top: 6.25rem;
	padding-bottom: 6.25rem;
}

.pt-6{
	padding-top: 5rem;
}